.navbar{
    background-color: #F6F6F6;
    width: 100%;
    position: fixed;
    padding: 0 12px;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    z-index: 99;
}

.brand{
    display: flex;
    flex-direction: row;
    align-items: center;
}

.brandTitle{
    margin-left: 9px;
    display: inline-block;
    font-size: 1.2em;
}

.logOutLink{
  color: #000;
  text-decoration: none;
}
