li{
    position: relative;
    margin-bottom: 0;
    transition: 0.5s;
}

.cardHeaderRound{
    display: flex;
    position: relative;
    border-radius: 18px;
    border-width: 1px;
    border-style: solid;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    margin-right: 10.5px;
    margin-left: 10.5px;
}

.headerRoundImg{
    position: absolute;
    top: 15px;
}

.headerRoundImg.left{
    left: 21px;
}

.headerRoundImg.right{
    right: 21px;
}

.cardHeaderRound.vitality{
    color: #B71A10;
    border-color: #B71A10;
    box-shadow: 0 0 4px 1px rgba(183, 26, 16, 0.2);
}

.cardHeaderRound.protection{
    color: #9B9B9B;
    border-color: #9b9b9b;
    box-shadow: 0 0 4px 1px rgba(155, 155, 155, 0.2);
}

.cardHeaderRound.mana{
    color: #1B3E81;
    border-color: #1b3e81;
    box-shadow: 0 0 4px 1px rgba(27, 62, 129, 0.2);
}

.cardHeaderRound.level{
    color: #1B8136;
    border-color: #1b8136;
    box-shadow: 0 0 4px 1px rgba(27, 129, 54, 0.2);
}

.cardContainerContent{
    width: 100%;
    height: 100%;
    padding: 0 12px;
}

.cardContainerContent>p{
    padding-top: 10px;
    padding-bottom: 7px;
    border-bottom: 1px solid #000;
}

.cardButtonsRound{
    display: flex;
    visibility: hidden;
    position: relative;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 0;
    top: auto;
    position: relative;
    z-index: 0;
    transition: 0.5s;
}

.buttonsRound{
    display: flex;
    border-radius: 100%;
    background:linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%), linear-gradient(190.1deg, rgba(27, 129, 54, 0.87) 0.52%, rgba(255, 255, 255, 0) 92.44%), #FFFFFF;
    align-items: center;
    justify-content: center;
    width: 68px;
    height: 0;
    margin-right: 10.5px;
    margin-left: 10.5px;
    transition: 0.5s;
    cursor: pointer;
}

.buttonsRound:first-child img{
    border-radius: 100%;
    box-shadow: -3px 4px 6px 0 rgba(0, 0, 0, 0.25);
}

li:hover{
    margin-bottom: 112px;
    transition: 0.5s;
}

li:hover .cardButtonsRound{
    visibility: visible;
    position: absolute;
    height: 112px;
    top: 149px;
    transition: 0.5s;
}

li:hover .cardButtonsRound .buttonsRound{
    height: 68px;
    transition: 0.5s;
}

li .cardButtonsRound .buttonsRound img{
    height: 0;
    width: 48px;
    transition: 0.5s;
}

li:hover .cardButtonsRound .buttonsRound img{
    height: 48px;
    transition: 0.5s;
}