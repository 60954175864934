input{
    width: calc(247px * 0.8);
    height: calc(55px * 0.8);
    margin-bottom: calc(20px * 0.8);
    padding-left: calc(12px * 0.8);
    padding-right: calc(12px * 0.8);
    border: none;
    box-shadow: 0 0 4px 1px rgba(0, 0, 0, 0.2);
    border-radius: 15px;
    font-size: calc(18 * 0.8);
}

.logo{
    height: calc(131px * 0.8);
    width: calc(131px * 0.8);
}

input:focus{
    outline: none;
    box-shadow: 0 0 4px 4px rgba(0, 0, 0, 0.2);
    transition: 0.5s;
}

form{
    background: linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%), linear-gradient(190.1deg, rgba(27, 129, 54, 0.87) 0.52%, rgba(255, 255, 255, 0) 92.44%), #FFFFFF;
    max-width: calc(318px * 0.8);
    width: calc(90% * 0.8);
    height:auto;
    padding: calc(20px * 0.8) !important;
    border: none;
    box-shadow: 0 0 4px 1px rgba(0, 0, 0, 0.2);
    border-radius: 15px;
    margin-top: 22px !important;
}

fieldset{
    text-align: center;
}

legend{
    font-family: 'Roboto', Verdana, Geneva, Tahoma, sans-serif !important;
    margin-bottom: calc(20px * 0.8) !important;
    color: #1B8136;
}

.input{
    padding-left: calc(12px * 0.8);
    padding-right: calc(12px * 0.8);
    background-color: #FFF;
    color: #1B8136;
}

.loginContainer{
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: top;
  align-items: center;
  box-sizing: border-box;
}