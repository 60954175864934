/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
   start
*/

@font-face {
    font-family: "Roboto";
    src: url(./assets/Roboto/Roboto-Regular.ttf);
}

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(201, 251, 214, 0.58) 100%), rgba(27, 129, 54, 0.87);
	line-height: 1;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}

/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
   end
*/

*{
    box-sizing: border-box;
    font-family: 'Roboto', Verdana, Geneva, Tahoma, sans-serif !important;;
    
}

:root{
    --cardHeaderHeight: 52.29px;
}

html, body{
    height: 100%;
    overflow: hidden;
}

#root{
    height: 100%;
    display: flex;
    margin-top: 42px;
    padding-bottom: 42px;
    flex-direction: column;
    justify-content: top;
    align-items: center;
    box-sizing: border-box;
    font-family: 'Roboto', Verdana, Geneva, Tahoma, sans-serif !important;;
    overflow: auto;
}

.card{
    align-items: center;
    justify-content: center;
    background:linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%), linear-gradient(190.1deg, rgba(27, 129, 54, 0.87) 0.52%, rgba(255, 255, 255, 0) 92.44%), #FFFFFF;
    border-radius: 15px;
    border-color: #DFDFDF;
    border-style: solid;
    border-width: 1.5px;
    width: 300px;
    height: 150px;  
    margin-top: 2em;  
    box-shadow: 0 2px 4px 2px rgba(0, 0, 0, 0.2);
}

.cardContainer{
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: calc(100% - var(--cardHeaderHeight));
    z-index: 2;
}

.cardHeader{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: var(--cardHeaderHeight);
    width: 100%;
    background-color: #F6F6F6;
    border-bottom-color: #DFDFDF;
    border-bottom-width: 1px;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;  
    box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.2);
}

.cardHeaderText{
    color: #1B8136;
    line-height: 21px;
    margin-right: 10.5px;
    margin-left: 10.5px;
}

.button{
    background-color: #1B8136;
    border: none;
    border-radius: 15px;
    color: #FFF;
    cursor: pointer;
    width: calc(247px * 0.8);
    height: calc(55px * 0.8);
    outline: none;
};

.button:focus{
    box-shadow: 0 0 4px 4px rgba(0, 0, 0, 0.2);
    transition: 0.5s;
}

.cardHeaderRound, 
.cardHeaderText,
.cardContainerContentBody{
    transition: 0.5s;
}