.sheet{
    height: auto !important;
    position: relative;
    margin-bottom: 2em;
    transition: 0.5s;
}

.cardContainerContentBody{
    display: flex;
    align-items: center;
    justify-content: right;
    padding-top: 32px;
    padding-bottom: 65px;
    height: auto;
}

.cardContainerContentBody>img{
    min-height: 191px;
    min-width: 191px;
}

.attr{
    text-align: center;
}

.attr > p{
    padding-top: 10px;
    padding-bottom: 7px;
    border-bottom: 1px solid #000;
}


.attrItem{
    margin-top: 3px;
    margin-bottom: 11px;
    display: flex;
    justify-content: space-between;
}

.attrItem span{
    width: calc(36px * 0.8);
    height: calc(35px * 0.8);
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.attrItem span.combat{
    color:  #A0522D;
    border: 1px solid #A0522D;
    box-shadow: 0px 0px 4px #A0522D;
}

.attrItem span.knowledge{
    color:  #FFCD43;
    border: 1px solid #FFCD43;
    box-shadow: 0px 0px 4px #FFCD43;
}

.attrItem span.skill{
    color:  #DDC6AE;
    border: 1px solid #DDC6AE;
    box-shadow: 0px 0px 4px #DDC6AE;
}

.attrItem span.luck{
    color:  #9ADB07;
    border: 1px solid #9ADB07;
    box-shadow: 0px 0px 4px #9ADB07;
}

.mochilaIcon{
    position: absolute;
    left: -20px;
    top: calc(100% - 50px);
    transition: 0.5s;
}

.equips{
    opacity: 0;
    display: none;
    transition: 0.5s;
}

.restosMochila{
    visibility: hidden;
    opacity: 0;
    transition: 0.5s;
    position: absolute;
    top: calc(100% - 101px);
    left: calc(50% - 74.5px);
}

.inventario{
    background: linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%), linear-gradient(190.1deg, #D47A3D 0.52%, rgba(255, 255, 255, 0) 92.44%), #FFFFFF !important;
    border-color: #8C4F26 !important;
    border-width: 1px !important;
    transition: 0.5s;
}

.inventario .cardHeader{
    background-color: #8C4F26 !important;
    border-bottom-color: #8C4F26 !important;
    transition: 0.5s;
}

.inventario .mochilaIcon{
    left: calc(50% - 43.5px);
    top: -16px;
    transition: 0.5s;
}

.inventario .equips{
    display: block;
    opacity: 1;
    transition: 0.5s;
}

.inventario .restosMochila{
    visibility: visible;
    opacity: 1;
    transition: 0.5s;
}

.inventario .cardHeaderRound, 
.inventario .cardHeaderText,
.inventario .cardContainerContentBody{
    opacity: 0 !important;
    transition: 0.5s;
}

.inventario .cardContainerText{
    display: none;
}